import React from "react";
import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaWordpress,
  FaGoodreads,
  FaYoutube
} from "react-icons/fa";
import Image from "../Image/Image.js";
import "../../dist/css/responsive.css";

const CalebFooter = () => {
  return (
    <React.Fragment>
      <footer className="footer-style-two" id="scroll-section-final">
        <div className="footer-upper">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="about-me">
                <div>
                  <div className="image-box">
                    <figure className="image">
                      <a href="#">
                        <Image filename="avatar.jpg" />
                      </a>
                    </figure>
                  </div>
                  <div>
                    <h2 className="author-text">About the Author</h2>
                    <div className="text author-text">
                      <p>
                        Caleb Woods began writing at a young age, first to cope with bullying at
                        school and later to soothe his depressing thoughts. Growing up in the small
                        town of Pisgah, Alabama, he was surrounded by religion and found it
                        increasingly difficult to reconcile his faith with his sexual orientation.
                        Caleb was told he would spend an eternity in hell for being gay – and he
                        believed it. He was first officially diagnosed with PTSD in high school
                        after his closest friend died unexpectedly. He moved away to college but
                        ignored his symptoms and didn’t seek help for his mental illness. After
                        years of suffering silently, he began to accept his sexual orientation and
                        eventually met his now husband, Luke. Despite a happy marriage, Caleb
                        continued to suffer with symptoms of PTSD. At their peak, the night terrors
                        and panic attacks finally drove him to seek professional help. His doctor
                        quickly confirmed that PTSD was the diagnosis and it wasn’t something to be
                        taken lightly. Today, Caleb receives treatment for PTSD, panic disorder, and
                        depression by attending reoccurring therapy sessions. Throughout these
                        years, Caleb wrote poetry about his specific struggles surrounding mental
                        illness and growing up gay in the Bible Belt. In his debut book, "Harnessing
                        Darkness: Expressing Mental Illness Through Poetry," Caleb reveals his most
                        personal thoughts – some dark, some light, some suffering, some uplifting,
                        but all existential. Currently, Caleb lives with his husband in Birmingham,
                        Alabama. He is a full-time writer, author, and poet who enjoys reading,
                        traveling, playing board games, and collecting pop culture memorabilia.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="copyright-text text-left">
                  Copyright &copy; 2022 Caleb Woods. All rights reserved.
                </div>
              </div>
              <div className="social-links">
                <a href="https://www.facebook.com/pg/calebwoodswriter/">
                  <FaFacebook />
                </a>
                <a href="https://twitter.com/CalebisLOST">
                  <FaTwitter />
                </a>
                <a href="https://www.instagram.com/calebislost/">
                  <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/in/calebwoodswriter/">
                  <FaLinkedin />
                </a>
                <a href="https://calebislost.wordpress.com">
                  <FaWordpress />
                </a>
                <a href="https://www.goodreads.com/book/show/46816647-harnessing-darkness">
                  <FaGoodreads />
                </a>
                <a href="https://www.youtube.com/channel/UCpmPPelAKvZPgfbB1z-REmw">
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        body {
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          color: #777777;
          line-height: 1.8em;
          font-weight: 400;
          background: #ffffff;
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing: antialiased;
        }

        .social-links {
          display: flex;
        }

        a {
          text-decoration: none;
          cursor: pointer;
          color: #c18f59;
          margin-left: 10px;
        }

        a:hover,
        a:focus,
        a:visited {
          text-decoration: none;
          outline: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          position: relative;
          font-family: "EB Garamond", serif;
          font-weight: normal;
          margin: 0px;
          background: none;
          line-height: 1.4em;
        }

        input,
        button,
        select,
        textarea {
          font-family: "Poppins", sans-serif;
        }

        p {
          position: relative;
          line-height: 1.8em;
        }

        .strike-through {
          text-decoration: line-through;
        }

        .auto-container {
          position: static;
          max-width: 1200px;
          padding: 0px 15px;
          margin: 0 auto;
        }

        .medium-container {
          max-width: 850px;
        }

        .page-wrapper {
          position: relative;
          margin: 0 auto;
          width: 100%;
          min-width: 300px;
        }

        .page-container {
          position: relative;
          width: 100%;
          border: 12px solid transparent;
          background: #0d0f11;
        }

        ul,
        li {
          list-style: none;
          padding: 0px;
          margin: 0px;
        }

        .theme-btn {
          display: inline-block;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }

        .centered {
          text-align: center;
        }

        .btn-style-one {
          position: relative;
          padding: 11px 40px;
          line-height: 24px;
          background: #c18f59;
          color: #ffffff;
          border: 2px solid #c18f59;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-one:hover {
          background: none;
          border-color: #c18f59;
          color: #c18f59;
        }

        .btn-style-two {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #ffffff;
          color: #222222;
          border: 2px solid #ffffff;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-two:hover {
          background: none;
          border-color: #ffffff;
          color: #ffffff;
        }

        .btn-style-three {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: none;
          text-transform: uppercase;
          color: #46c1d7;
          border: 2px solid #d0d0d0;
          font-size: 13px;
          font-weight: 600;
          border-radius: 0px;
        }

        .btn-style-three:hover {
          background: #46c1d7;
          border-color: #46c1d7;
          color: #ffffff;
        }

        .btn-style-three .fa {
          position: relative;
          top: 0px;
          padding-left: 5px;
          font-weight: 400;
        }

        .btn-style-four {
          position: relative;
          padding: 8px 35px;
          line-height: 24px;
          background: #46c1d7;
          text-transform: uppercase;
          color: #ffffff;
          border: 2px solid #46c1d7;
          font-size: 15px;
          font-weight: 700;
          border-radius: 3px;
        }

        .btn-style-four:hover {
          background: none;
          color: #46c1d7;
        }

        .btn-style-five {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #bf9456;
          border: 2px solid #bf9456;
          font-size: 14px;
          font-weight: 400;
          border-radius: 0px;
          font-family: "Roboto Slab", serif;
        }

        .btn-style-five:hover {
          background: #bf9456;
          color: #ffffff;
        }

        .btn-style-six {
          position: relative;
          padding: 11px 25px;
          line-height: 24px;
          background: #6775de;
          color: #ffffff;
          border: 2px solid #6775de;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-six:hover {
          background: none;
          color: #6775de;
        }

        .btn-style-seven {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #8ecc3b;
          color: #ffffff;
          border: 2px solid #8ecc3b;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-seven:hover {
          background: none;
          color: #8ecc3b;
        }

        .btn-style-eight {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #ffffff;
          border: 2px solid #ffffff;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-eight:hover {
          color: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-nine {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: #010101;
          color: #ffffff;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-nine:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-ten {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #010101;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Georgia", serif;
        }

        .btn-style-ten:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .theme_color {
          color: #c18f59;
        }

        .light-font {
          font-weight: 300;
        }

        .regular-font {
          font-weight: 400;
        }

        .semibold-font {
          font-weight: 600;
        }

        .medium-font {
          font-weight: 500;
        }

        .bold-font {
          font-weight: 700;
        }

        .preloader {
          position: fixed;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          z-index: 999999;
          background-color: #ffffff;
          background-position: center center;
          background-repeat: no-repeat;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        .scroll-to-top {
          position: fixed;
          bottom: 15px;
          right: 15px;
          width: 40px;
          height: 40px;
          color: #ffffff;
          font-size: 14px;
          text-transform: uppercase;
          line-height: 38px;
          text-align: center;
          z-index: 100;
          cursor: pointer;
          border-radius: 50%;
          background: #222222;
          display: none;
          -webkit-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          transition: all 300ms ease;
        }

        .scroll-to-top:hover {
          color: #ffffff;
          background: #c18f59;
        }

        .footer-style-two {
          position: relative;
          color: #aaaaaa;
          background: #403e42;
        }

        .footer-style-two .footer-upper {
          position: relative;
          padding: 110px 0px 70px;
          color: #999999;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .footer-style-two .footer-bottom {
          position: relative;
          font-size: 14px;
          padding: 15px 0px;
          line-height: 30px;
          color: #777777;
          font-weight: 500;
          background: #38363a;
        }

        .footer-style-two .footer-bottom img {
          display: inline-block;
          max-width: 100%;
        }

        .footer-style-two .footer-bottom a {
          color: #a6a6a6;
        }

        .footer-style-two .footer-bottom a:hover {
          color: #ffffff;
        }

        .footer-style-two h2 {
          font-size: 20px;
          color: #ffffff;
          font-weight: 700;
          font-family: "Roboto Slab", serif;
          margin-bottom: 20px;
        }

        .footer-style-two .about-me {
          position: relative;
        }

        .footer-style-two .image-column {
          position: relative;
          margin-bottom: 20px;
        }

        .footer-style-two .image-box {
          position: relative;
          margin-bottom: 10px;
          max-width: 70%;
          margin: auto;
        }

        .footer-style-two .image-box img {
          position: relative;
          display: block;
          width: 100%;
          opacity: 0.7;
        }

        .footer-style-two .image-column .more-link a {
          font-size: 14px;
          color: #ffffff;
          font-weight: 400;
          font-family: "Roboto Slab", serif;
        }

        .footer-style-two .info-column {
          position: relative;
          margin-bottom: 20px;
        }

        .footer-style-two .info-column .info li {
          position: relative;
          line-height: 24px;
          margin-bottom: 12px;
          color: #aaaaaa;
        }

        .footer-style-two .info-column .info li:last-child {
          margin: 0px;
        }

        .footer-style-two .contact-form {
          position: relative;
        }

        .footer-style-two .contact-form .form-group {
          position: relative;
          margin-bottom: 20px;
        }

        .footer-style-two .contact-form .form-group input[type="text"],
        .footer-style-two .contact-form .form-group input[type="tel"],
        .footer-style-two .contact-form .form-group input[type="email"],
        .footer-style-two .contact-form .form-group select,
        .footer-style-two .contact-form .form-group textarea {
          position: relative;
          font-family: "Roboto Slab", serif;
          display: block;
          width: 100%;
          line-height: 24px;
          padding: 11px 20px;
          height: 44px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: #ffffff;
          font-size: 13px;
          background: #373539;
          border-radius: 2px;
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
        }

        .footer-style-two .contact-form .form-group textarea {
          height: 100px;
          resize: none;
        }

        .footer-style-two .contact-form .form-group input[type="text"]:focus,
        .footer-style-two .contact-form .form-group input[type="tel"]:focus,
        .footer-style-two .contact-form .form-group input[type="email"]:focus,
        .footer-style-two .contact-form .form-group select:focus,
        .footer-style-two .contact-form .form-group textarea:focus {
          background: #111111;
        }

        .footer-style-two .contact-form input[type="submit"],
        .footer-style-two .contact-form button {
          font-size: 12px;
          text-transform: uppercase;
          padding: 7px 30px;
          font-family: "Roboto Slab", serif;
          border-radius: 0px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default CalebFooter;
